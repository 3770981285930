<template>
    <div>
        <button class="text-lg bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 text-white py-2 px-4 rounded font-script" type="button" style="transition: all .15s ease" v-on:click="toggleModal()">
            Ajouter un cadeau
        </button>
        <div v-if="showEdit" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-full" style="max-width: 600px">
                <div class="relative px-4 py-10 bg-white shadow rounded-3xl sm:p-10">
                    <div class="max-w-md mx-auto">
                        <div class="flex items-center space-x-5">
                            <div class="block pl-2 font-semibold text-xl self-start text-gray-700">
                                <h2 class="leading-relaxed">Ajout d'un cadeau</h2>
                            </div>
                        </div>
                        <div class="flex flex-col pl-2">
                            <label class="leading-loose">Titre <span class="text-red-500">*</span></label>
                            <input v-model="presentToCreate.title" type="text" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 dark:border-gray-700 rounded-md focus:outline-none text-gray-600 dark:text-gray-50 bg-white dark:bg-gray-800 ">
                        </div>
                        <div class="flex flex-col pl-2">
                            <label class="leading-loose">Lien</label>
                            <input v-model="presentToCreate.link" type="url" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 dark:border-gray-700 rounded-md focus:outline-none text-gray-600 dark:text-gray-50 bg-white dark:bg-gray-800 ">
                        </div>
                        <div class="flex flex-col pl-2">
                            <label class="leading-loose">Prix</label>
                            <div class="grid grid-cols-3">
                                <input v-model="presentToCreate.price" type="number" class=" text-right col-span-2 px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 dark:border-gray-700 rounded-md focus:outline-none text-gray-600 dark:text-gray-50 bg-white dark:bg-gray-800 ">
                                <p class="ml-2 self-center text-xl">€</p>
                            </div>
                        </div>
                        <div class="flex flex-col pl-2">
                            <label class="leading-loose">Commentaire</label>
                            <textarea v-model="presentToCreate.comment" type="text" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 dark:border-gray-700 rounded-md focus:outline-none text-gray-600 dark:text-gray-50 bg-white dark:bg-gray-800 "></textarea>
                        </div>
                        <div v-if="errorMessage" class="flex justify-center items-center mt-2">
                            <p class="text-red-500">{{errorMessage}}</p>
                        </div>
                        <div class="pt-4 flex items-center space-x-4">
                            <button class="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none" v-on:click="toggleModal()">
                                <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Annuler
                            </button>
                            <button v-on:click="toCreate" class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Créer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showEdit" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'PresentCreate',
    data(){
        return {
            showEdit: false,
            errorMessage: null,
            presentToCreate: {
                title: '',
                link: '',
                price: '',
                comment: '',
                list_id: this.$route.params.id,

            }
        }
    },
    methods:{
        toggleModal(){
            this.showEdit = !this.showEdit;
            this.errorMessage = null;
        },
        toCreate(){
            this.createPresent(this.presentToCreate);
            this.showEdit = !this.showEdit;
            this.errorMessage = null;
            this.presentToCreate = {
                title: '',
                link: '',
                price: '',
                comment: '',
                list_id: this.$route.params.id,
            }
        },
        ...mapActions(['createPresent']),
    },

}
</script>