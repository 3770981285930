<template>
    <div>
        <button class="text-xl text-left w-full text-black pt-2 pb-0 rounded hover:bg-gray-300 font-script" type="button" style="transition: all .15s ease" v-on:click="toggleModal()">
            {{present.title}}
        </button>
        <div v-if="showEdit" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-full" style="max-width: 600px">
                <div class="relative px-4 py-10 bg-white shadow rounded-3xl sm:p-10">
                    <div v-if="!editMode" class="max-w-md mx-auto text-xl">
                        <div class="flex items-center space-x-5">
                            <div class="block pl-2 w-full font-semibold self-start text-gray-700">
                                <h2 class="leading-relaxed text-2xl">{{present.title}}</h2>
                                <div class="flex flex-row justify-between items-center">
                                    <p v-if="present.price" class="font-normal">Prix : <span class="font-bold text-blue-400">{{present.price}}€</span></p>
                                    <a v-if="present.link" :href="present.link" target="_blank">
                                        <button class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 flex justify-center items-center text-white px-2 py-1 rounded-md focus:outline-none">
                                            Lien du cadeau
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                            </svg>    
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-if="present.comment" class="flex flex-col pl-2 mt-4">
                            <blockquote class="leading-loose bg-blue-100 rounded p-2 max-h-72 overflow-scroll">{{present.comment}}</blockquote>
                            <figcaption>- {{profil}}</figcaption>
                        </div>
                        <div v-if="errorMessage" class="flex justify-center items-center mt-2">
                            <p class="text-red-500">{{errorMessage}}</p>
                        </div>
                        <div class="pt-4 flex items-center justify-end space-x-4">
                            <button v-on:click="editPresent" class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 flex justify-center items-center w-full text-white px-3 py-2 rounded-md focus:outline-none">Editer </button>
                            <button class="flex justify-end items-center bg-gray-200 text-gray-900 px-3 py-2 rounded-md focus:outline-none" v-on:click="toggleModal()">
                                <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Fermer
                            </button>
                        </div>
                    </div>
                    <div v-if="editMode" class="max-w-md mx-auto text-xl">
                        <div class="flex flex-col pl-2">
                            <label class="leading-loose">Titre <span class="text-red-500">*</span></label>
                            <input v-model="presentToEdit.title" type="text" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 dark:border-gray-700 rounded-md focus:outline-none text-gray-600 dark:text-gray-50 bg-white dark:bg-gray-800 ">
                        </div>
                        <div class="flex flex-col pl-2">
                            <label class="leading-loose">Lien</label>
                            <input v-model="presentToEdit.link" type="url" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 dark:border-gray-700 rounded-md focus:outline-none text-gray-600 dark:text-gray-50 bg-white dark:bg-gray-800 ">
                        </div>
                        <div class="flex flex-col pl-2">
                            <label class="leading-loose">Prix</label>
                            <div class="grid grid-cols-3">
                                <input v-model="presentToEdit.price" type="number" class=" text-right col-span-2 px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 dark:border-gray-700 rounded-md focus:outline-none text-gray-600 dark:text-gray-50 bg-white dark:bg-gray-800 ">
                                <p class="ml-2 self-center text-xl">€</p>
                            </div>
                        </div>
                        <div class="flex flex-col pl-2">
                            <label class="leading-loose">Commentaire</label>
                            <textarea v-model="presentToEdit.comment" type="text" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 dark:border-gray-700 rounded-md focus:outline-none text-gray-600 dark:text-gray-50 bg-white dark:bg-gray-800 " style="min-height: 150px"></textarea>
                        </div>
                        <div class="pt-4 flex items-center space-x-4">
                            <button class="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none" v-on:click="closeEdit">
                                <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Annuler
                            </button>
                            <button v-on:click="toEdit" class="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Editer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showEdit" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'PresentItem',
    props: {
        present: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showEdit: false,
            errorMessage: null,
            editMode: false,
            presentToEdit: {
                title: '',
                price: '',
                link: '',
                comment: ''
            }
        }
    },
    computed: {
        ...mapState(['profil']),
    },
    methods:{
        toggleModal(){
            this.showEdit = !this.showEdit;
            this.errorMessage = null;
            this.editMode = false;
        },
        editPresent(){
            this.editMode = true;
            this.presentToEdit = {...this.present}
            let formatPrice = this.present.price.toString().replace(',', '.')
            this.presentToEdit.price = parseFloat(formatPrice);
        },
        closeEdit(){
            this.editMode = false;
            this.presentToEdit = null;
        },
        toEdit(){
            this.updatePresent(this.presentToEdit);
            this.toggleModal();
        },
        ...mapActions(['updatePresent'])
    },
}
</script>