<template>
    <div class="min-h-screen bg-wood flex flex-col items-center justify-center px-4">
        <div class="w-full bg-white p-4 m-6 mb-16 font-script shadow-paper">
            <h1 class=" text-5xl text-red-500 font-bold text-center">Ma liste de Noël</h1>
            <template v-if="loading">
                <div class="flex justify-center">
                    <img src="/img/Wedges-3s-200px.svg" style="height: 100px; width: 100px;">
                </div>
            </template>
            <template v-if="!loading">
                <template v-if="presents.length > 0">
                    <p class="text-xl text-gray-700 font-normal leading-relaxed">
                        <span class="mr-4"></span>Cher Père Noël, cette année j'ai été très sage ! Aussi je souhaiterais :
                    </p>
                    <ul>
                        <li v-for="present in presents" :key="present.id" class=" border-b border-blue-200">
                            <present-item :present="present" />
                        </li>
                    </ul>
                </template>
                <template v-if="presents.length === 0">
                    <p class="text-xl text-gray-700 font-normal leading-relaxed">
                        C'est ici que tu peux ajouter les cadeaux que tu souhaiterais
                        que le Père Noël t'apportes si tu as été bien sage cette année !
                    </p>
                    <p class="text-xl text-gray-700 font-normal leading-relaxed">
                        Commençons par créer ton premier cadeau, clique sur le bouton "Ajouter un cadeau"
                    </p>
                    <p class="text-xl text-gray-700 font-normal leading-relaxed text-center">
                        👇
                    </p>
                </template>
                <div class="flex justify-center mt-4">
                        <present-create />
                </div>
            </template>
        </div>
        <navbar />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Navbar from '../components/Navbar.vue'
import PresentCreate from '../components/presents/PresentCreate.vue';
import PresentItem from '../components/presents/PresentItemOwnList.vue';
export default {
    name: 'EditList',
    components: { Navbar, PresentCreate, PresentItem },
    data() {
        return {
            user: null,
        }
    },
    beforeMount() {
        this.getPresents(this.$route.params.id);
    },
    computed: {
        ...mapState(['presents', 'loading'])
    },
    methods: {
        ...mapActions(['getPresents'])
    },
}
</script>